import donateOnline from 'assets/images/donate-go/donate-online.svg';
import poolDonation from 'assets/images/donate-go/pool-donation.svg';
import nursiresGrow from 'assets/images/donate-go/nursires-grow.svg';
import plantTrees from 'assets/images/donate-go/plant-trees.svg';
import maintained from 'assets/images/donate-go/maintained.svg';
import impactGenerated from 'assets/images/donate-go/impact-generated.svg';

export const donationGoSection = [
  {
    id: 1,
    img: donateOnline,
    alt: 'donateOnline',
    title: 'You <span>Donate</span> Online',
  },
  {
    id: 2,
    img: poolDonation,
    alt: 'poolDonation',
    title: 'We <span>Pool</span> Donations',
  },
  {
    id: 3,
    img: nursiresGrow,
    alt: 'nursiresGrow',
    title: 'Our nurseries <span>grow</span> saplings',
  },
  {
    id: 4,
    img: plantTrees,
    alt: 'plantTrees',
    title: 'Teams <span>plant trees</span> during monsoons and early spring',
  },
  {
    id: 5,
    img: maintained,
    alt: 'maintained',
    title: 'Trees are <span>maintained</span> & monitored',
  },
  {
    id: 6,
    img: impactGenerated,
    alt: 'impactGenerated',
    title: 'Reports on <span>Impact</span> generated',
  },
];
