import React from 'react';

const CarouselButtonGroup = ({ next, previous }) => {
  return (
    <div className='carousel-button-group green-arrow'>
      <div className='navigations'>
        <button onClick={() => previous()} className='me-3'>
          <i className='icon icon-tree-leaf-left'></i>
        </button>
        <button onClick={() => next()}>
          <i className='icon icon-tree-leaf-right'></i>
        </button>
      </div>
    </div>
  );
};

export default CarouselButtonGroup;
