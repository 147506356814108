import React, { useState, useEffect } from 'react';

import Carousel from 'react-multi-carousel';

import { donationGoSection } from 'assets/data/donation-go-section';

import CarouselButtonGroup from 'utils/carousel-arrow';

export default function DonationsGoSection() {
  const [isMobile, setIsMobile] = useState(false);

  const responsive = {
    desktop: {
      breakpoint: {
        max: 567,
        min: 320,
      },
      items: 1,
    },
  };

  useEffect(() => {
    window.innerWidth < 567 ? setIsMobile(true) : setIsMobile(false);
  }, []);

  return (
    <div className='donation-go'>
      <div className='container'>
        <div className='row donation-go-row'>
          <div className='col-md-12'>
            <section className='section'>
              <div className='section-title'>
                <h3 className='text-start'>
                  Where does your <span className='green'>donation go?</span>
                </h3>
              </div>
              <div className='row'>
                {isMobile ? (
                  <Carousel
                    arrows={false}
                    autoPlaySpeed={3000}
                    draggable
                    infinite
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={true}
                    swipeable
                    ssr={false}
                    responsive={responsive}
                    customButtonGroup={<CarouselButtonGroup />}>
                    {donationGoSection.map((item) => {
                      return (
                        <div className='col-md-2 col-sm-4' key={item.id}>
                          <div className='img-box-wrapper'>
                            <div className='img-box'>
                              <img data-src={item.img} className='lazyload' alt={item.alt} />
                            </div>
                            <div className='content-box'>
                              <p dangerouslySetInnerHTML={{ __html: item.title }}></p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                ) : (
                  donationGoSection.map((item) => {
                    return (
                      <div className='col-md-2 col-sm-4' key={item.id}>
                        <div className='img-box-wrapper'>
                          <div className='img-box'>
                            <img data-src={item.img} className='lazyload' alt={item.alt} />
                          </div>
                          <div className='content-box'>
                            <p dangerouslySetInnerHTML={{ __html: item.title }}></p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
